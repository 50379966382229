/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/
@import '../variable'; 

/*******************
Login register and recover password Page
******************/
.login-register{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height:100%;
  width: 100%;
  padding:10% 0;  
  position:fixed;
}
.login-box{
  width:400px;
  margin:0 auto;
  
  .footer{
    width:100%;
    left:0px;
    right:0px;
  }
  .social {
    display:block;
    margin-bottom:30px;
  }
}
#recoverform{
   display:none; 
}
.login-sidebar {
    padding: 0px;
    
    margin-top: 0px;
    .login-box{
        right: 0px;
        position: absolute;
        height: 100%;
    }
    
}




